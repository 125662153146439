import type {Maybe} from "../../web-paintec/src/shared/utils/thecookies";

const enum Language {
  ES = "es_es",
  EN = "en",
}
const DEFAULT_LANGUAGE: Language = Language.ES;

export { DEFAULT_LANGUAGE, Language };

export const Languages: { title: string; value: Language }[] = [
  { title: "Español", value: Language.ES },
  { title: "Inglés", value: Language.EN },
];

export const getLanguageTitle = (value: Language): string | undefined => {
  const language = Languages.find(lang => lang.value === value);
  return language?.title; // Devuelve el 'title' o undefined si no encuentra coincidencia
};

export const getCodeByTitle = (value: string): Language => {
  const language = Languages.find(lang => lang.title === value);
  return getCodeLang(language?.value);
}

function getCodeLang(language: Maybe<string>): Language {
  const code = language || DEFAULT_LANGUAGE;

  return code.replace("_", "-") as Language;
}